import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UserGuide])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './user_guide.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import SupportDescriptionOptiplus1 from "../../components/pages/UserGuide/partials/supportDescriptionOptiplus1/SupportDescriptionOptiplus1";
// import SupportDescriptionOptiplus3 from "../../components/pages/UserGuide/partials/supportDescriptionOptiplus3/SupportDescriptionOptiplus3";
// import SupportDescriptionOptiplus2 from "../../components/pages/UserGuide/partials/supportDescriptionOptiplus2/SupportDescriptionOptiplus2";
// import {getRoutes} from "../../components/shared/routes";
// import HowToOptiplus from "../../components/pages/UserGuide/partials/HowToOptiplus/HowToOptiplus";
// import FaqOptiplus from "../../components/pages/UserGuide/partials/faqOptiplus/FaqOptiplus";
// import SupportContactForm from "../../components/shared/supportContactForm/SupportContactForm";

// const UserGuidePage = () => {
//   const { t } = useTranslation();
//   const pageTitle = `${getRoutes().UserGuide.pageTitle} | ${getRoutes().OptiwaiPlus.pageTitle}`;


//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Well documented video guide'}/>

//       <div className={css.wrapper}>

//         <div className={css.SupportDescription1}>
//           <SupportDescriptionOptiplus1 />
//         </div>

//         <div className={css.SupportDescription3}>
//           <SupportDescriptionOptiplus3 />
//         </div>

//         <div className={css.SupportDescription2}>
//           <SupportDescriptionOptiplus2 />
//         </div>

//         <div className={css.HowTo}>
//           <HowToOptiplus />
//         </div>

//         <div className={css.QuestionsAnswers}>
//           <FaqOptiplus />
//         </div>

//         <div className={css.SupportContactForm} id={getRoutes().UserGuide.sections.contact}>
//           <SupportContactForm />
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default UserGuidePage;
